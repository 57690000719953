import { useState, useEffect } from "react";
import cognitoInfo from "./cognito-info.json";
import { fetchContent } from "./api";

function App() {
  // The load state of the app
  // Options: loading|done|error
  const [load, setLoad] = useState("loading");
  
  // Get Cognito authorization code from query parameters
  const queryParams = new URLSearchParams(window.location.search);
  const authCode = queryParams.get("code");

  useEffect(() => {
    // Redirect to Hosted UI if auth code isn't given
    if (authCode === null) {
      window.location.replace(cognitoInfo.hostedUIUrl);
      return;
    }

    // Try to get a JWT token using auth code
    fetch(new URL("/oauth2/token", cognitoInfo.authUrl), {
      method: "POST",
      body: (new URLSearchParams({
        grant_type: "authorization_code",
        client_id: cognitoInfo.clientId,
        redirect_uri: cognitoInfo.redirectUrl,
        code: authCode
      })).toString(),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(response => response.json())
      .then(token => {
        if ("error" in token) {
          // Redirect to Hosted UI if auth code is invalid
          if (token.error === "invalid_grant") {
            window.location.replace(cognitoInfo.hostedUIUrl);
            return;
          }
          
          // For other errors display a message
          setLoad("error");
          return;
        }
  
        // Upon successfully getting token
        setLoad("done");
        console.log(token);
        
      });
  }, [authCode]);

  // The content fetched from API
  const [content, setContent] = useState("");

  // Fetch content from API
  useEffect(() => {
    fetchContent("").then(fetchedContent => setContent(JSON.stringify(fetchedContent, null, 2)));
  }, [])
  
  // Handle error load state
  if (load === "error")
    return <p>Error</p>
  
  if (load === "loading")
    return <p>Loading...</p>;

  // ====== Handle done load state ======
  
  return (
    <>
      <textarea
        id="contentArea"
        value={content}
        style={{width: 700, height: 1000}}
        onChange={(event) => setContent(event.target.value)}
      />
    </>
  );
}

export default App;
